import React from 'react';
import CatoButton from '../cato-button';
import './style.scss';


const HeadlineTextButton = ({headline, headlineType, text, buttonLabel, buttonPath, className}) => {

    const headlineEl = headline ? headlineType === 'h1' ? <h1>{headline}</h1> : <h2>{headline}</h2> : null;

    return (
        <div className={`headline-text-button-wrapper ${className}`}>
            {
                headline && headlineEl
            }
            {text && <div className="text" dangerouslySetInnerHTML={{__html: text}}/>}
            {buttonLabel && buttonPath && <CatoButton className={'green thin oval mid no-transform'} label={buttonLabel} path={buttonPath}/>}
        </div>
    )
};

export default HeadlineTextButton;