import React, {useState} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SideMenu from '../components/side-menu';
import HeadlineTextButton from '../components/headline-text-button';
import ContentLightboxWithSubmenu from '../components/content-lightbox-sidemenu';
import ContentLightbox from '../components/content-with-lightbox';
import StructuredData from'../components/structured-data';
import FAQ from '../components/faq';
import {isEmpty} from 'lodash';
import '../assets/styles/pages/hub.scss';

const HubPage = (props) => {

    const {data} = props;


    const seoSource = data.currentInnerPage ? data.currentInnerPage : data.hubParent;

    const { FAQSection, hubTopParent } = seoSource;

    const FAQparent = data.hubParent.FAQSection;


    const FAQcomp = FAQSection && FAQSection.faq && FAQSection.faq.length? FAQSection
        : FAQparent && FAQparent.faq && FAQparent.faq.length ? FAQparent
        : null;

    const [focus, setFocus] = useState(null);

    const sortItems = (a,b) => {
        if(a.menuOrder > b.menuOrder){
            return 1
        } else if (a.menuOrder < b.menuOrder){
            return -1
        }
        return 0
    }

    const allInnerPages = [...props.data.hubInnerPages.nodes, ...props.data.hubInnerInnerPages.nodes];

    const hubInnerPagesMenuOrder = props.data.hubParent.hubLobbyParent.hubInnerPages ? props.data.hubParent.hubLobbyParent.hubInnerPages.map(i => i.title) : ['test'];

    const hubInnerPagesMainContent = props.data.hubParent.content;

    /**
     * Maps inner pages with restructured data for side menu while also ordering according to
     * hub parent menu order
     * @type {Array.<*>}
     */
    const innerPages = allInnerPages.map(item =>({
        name: item.title,
        path: `${data.hubParent.path}${item.slug}/`,
        menuOrder: hubInnerPagesMenuOrder.indexOf(item.title),
        hubParentSummary: item.hubInnerParent.hubParentSummary,
        buttonLabel: item.hubInnerParent.buttonText,
        parentElement: item.wpParent,
        children: props.data.hubInnerPages.nodes.filter(page => page.parent && page.parent.node.title === item.title),
        content: item.hubInnerParent.hubInnerContent
    })).sort((a,b) => sortItems(a,b));

//parent subItem builder
    let parentSubitemsArray = [];
    const parentSubitemd = data?.hubParent?.hubTopParent?.hubTopContent && data.hubParent.hubTopParent.hubTopContent.map(item => {
        {parentSubitemsArray = [...parentSubitemsArray, {name: item.h2}]}
    })


    const theSubItems = {subItems: parentSubitemsArray}

    const hubParentMenuObj = {
        name: data.hubParent.title,
        path: `${data.hubParent.path}`,
        content: data.hubParent?.hubTopParent?.hubTopContent,
        menuOrder: 0,
        subItems: data.hubParent && data.hubParent.title == seoSource.title && [{subItems: [...parentSubitemsArray]}]
    };

    //sub links of parent page
    const hubParentMenuObjSubItems = hubTopParent && hubTopParent.hubTopContent && hubTopParent.hubTopContent.map(item =>({
        name: item.h2,
        parentElement: null,
        content: item.p,
        path: `${data.hubParent.path}${item.slug}/`,
        templateFileName: seoSource.templateFileName

    })).sort((a,b) => sortItems(a,b));

    const innerPagesForHubMenu = [
        hubParentMenuObj,
        ...innerPages.filter(item => item.parentElement.node.templateFileName === 'hub-parent.php')
    ];

    const genSubItems = (item) => {
        return item.content.reduce((acc, topic) => {
            if (topic.h2 && topic.h2.length) {
                return [...acc, {name: topic.h2}]
            }
            return acc
        }, [])
    }

    const genContent = () => {
        if(!data.currentInnerPage) {
            return (
                <section className="content-section">

                    <SideMenu
                        className={'no-border'}
                        parentSubMenu={hubParentMenuObjSubItems}
                        menu={innerPagesForHubMenu}
                        banner={null}
                        // focus={data.hubParent.title}
                        focus={focus}
                        showOnlyCurrentPage={false}
                        topH3={true}ƒ
                    />
                    <div className={'content ' + (hubTopParent && hubTopParent.hubTopContent ? 'content-with-lightbox no-padding-content-with-lightbox' : 'hub' )}>
                        {

                            hubInnerPagesMainContent
                                ?
                                <div className={'top-parent-text-area'} dangerouslySetInnerHTML={{__html:hubInnerPagesMainContent}} />
                                :
                                null
                        }

                        {
                            hubTopParent.hubTopContent
                                ?
                                <ContentLightbox
                                    menu={innerPagesForMenu}
                                    focusType={false}
                                    hubInnerPagesMainContent={hubInnerPagesMainContent ? hubInnerPagesMainContent : null}
                                    setFocus={setFocus}
                                    banner={null}
                                    focus={focus}
                                    path={props.location.pathname}
                                    topH3={true}
                                    mainTitle={null}
                                    mainDescription={null}
                                    content={(hubTopParent.hubTopContent && hubTopParent.hubTopContent.map(item => ({
                                        h2: item.h2,
                                        h3: item.h3,
                                        p: item.p,
                                        images: item.images,
                                        showLightbox: item.showLightbox,
                                        resource: item.resource && !isEmpty(item.resource)? {
                                            title: item.resource[0].title,
                                            image: item.resource[0].resourceFields.resourceSmallImage,
                                            description: item.resource[0].resourceFields.resourceShortDescription,
                                            linkText: item.resource[0].resourceFields.resourceButtonText,
                                            path: item.resource[0].resourceFields.resourceLink
                                        } : null
                                    }))) || null}
                                />
                                :
                                ''
                        }

                        {innerPages.map((page, i) => (
                            <HeadlineTextButton
                                key={`headline-text-button-${i}`}
                                headline={page.name}
                                headlineType="h2"
                                text={page.hubParentSummary}
                                buttonLabel={page.buttonLabel}
                                buttonPath={page.path}
                                className="hub-inner-page"
                            />
                        ))}

                    </div>
                </section>
            )
        }

        return (
            <ContentLightboxWithSubmenu
                menu={innerPagesForMenu}
                // singleFocus={true}
                setFocus={setFocus}
                banner={null}
                focus={focus}
                path={props.location.pathname}
                topH3={true}
                mainTitle={null}
                mainDescription={pageToDisplay.hubInnerParent.hubMainTitleAndGreenDescription && pageToDisplay.hubInnerParent.hubMainTitleAndGreenDescription.mainDescription}
                content={(pageToDisplay.hubInnerParent.hubInnerContent && pageToDisplay.hubInnerParent.hubInnerContent.map(item => ({
                    h2: item.h2,
                    h3: item.h3,
                    p: item.p,
                    images: item.images,
                    showLightbox: item.showLightbox,
                    resource: item.resource && !isEmpty(item.resource)? {
                        title: item.resource[0].title,
                        image: item.resource[0].resourceFields.resourceSmallImage,
                        description: item.resource[0].resourceFields.resourceShortDescription,
                        linkText: item.resource[0].resourceFields.resourceButtonText,
                        path: item.resource[0].resourceFields.resourceLink
                    } : null
                }))) || null}
            />
        )
    }



    const innerPagesForMenu = [
        hubParentMenuObj,
        ...innerPages.map((item, i) => {
        if (item.name === seoSource.title
            || (item.parentElement
                && (item.parentElement.node.title === seoSource.title
                ))
            || (item.children && item.children.find(child => child.node.title === seoSource.title))) {
            let subItems = [];

            const children = innerPages.filter(
                subItem => {
                    return subItem.parentElement && (subItem.parentElement.node.title === item.name) || subItem.name === seoSource.name
                }
            );
            if(item.content && item.name === seoSource.title) {
                const h2SubItems = genSubItems(item)

                subItems = [...subItems, {subItems: [...h2SubItems]}]
            }

            if (children.length) {
                subItems = [...subItems, ...children.map(i => ({
                    path: i.path,
                    name: i.name,
                    menuOrder: i.menuOrder,
                    subItems: i.name === seoSource.title ? genSubItems(i) : null
                })).sort((a, b) => sortItems(a, b))]
            }

            if(subItems.length) {
                return ({
                    ...item,
                    subItems: [...subItems]
                })
            }
        }

        return item;
    }).filter(item => item.parentElement.node.templateFileName === "hub-parent.php" || item.path.includes(props.path))];


    //create parent page sub items
    //create parent page sub items
    const innerPagesForParentPageMenu = hubParentMenuObjSubItems && [
        hubParentMenuObj,
        ...hubParentMenuObjSubItems.map((item, i) => {
            let subItems = [];
            const children = hubParentMenuObjSubItems.filter(
                subItem => {
                    return subItem && (subItem.title === item.name) || subItem.name === seoSource.name
                }
            );

            if(item.content && item.name === seoSource.title) {
                const h2SubItems = genSubItems(item)

                subItems = [...subItems, {subItems: [...h2SubItems]}]
            }

            if (children.length) {
                subItems = [...subItems, ...children.map(i => ({
                    path: i.path,
                    name: i.name,
                    menuOrder: i.menuOrder,
                    subItems: i.name === seoSource.title ? genSubItems(i) : null
                })).sort((a, b) => sortItems(a, b))]
            }

            if(subItems.length) {
                return ({
                    ...item,
                    subItems: [...subItems]
                })
            }
            return item;
        }).filter(item => item.templateFileName === "hub-parent.php" || item.path.includes(props.path))];



    const isHubLobby = !data.currentInnerPage;
    const allSections = [props.data.hubParent, ...props.data.hubInnerPages.nodes];

    const breadcrumbsLinks = typeof window !== 'undefined' ? document.location.pathname.split('/')
            .filter(i => i.length > 0)
            .map(item => {
                const page = {...allSections.find(p => p.node ? p.node.slug === item : p.slug === item)};

                if(page.node) {
                    page.path = page.node.path;
                    page.title = page.node.title;
                }

                return {
                    path: page.path,
                    title: page.title
                }
            })
        : null;

    const pageToDisplay = data.currentInnerPage ? data.currentInnerPage : null;

    const content = genContent();

    const structuredData = data.currentInnerPage
        ?
        <StructuredData
            type="Article"
            data={{
                headline: seoSource.title,
                publisher: {
                    '@type': 'Organization',
                    name: 'Cato Networks',
                    logo: {
                        '@type': 'ImageObject',
                        url: data.logoPage.structuredOrganizationData.organizationData.structuredLogo.sourceUrl
                    },
                },
                image: {
                    '@type': 'ImageObject',
                    url: data.logoPage.structuredOrganizationData.organizationData.structuredLogo.sourceUrl
                },
                author: {
                    '@type': 'Person',
                    name: data.currentInnerPage.author.name
                },
                datePublished: data.currentInnerPage.dataDate,
                dateModified: data.currentInnerPage.dataDate,
                dateCreated: data.currentInnerPage.dataDate,
                articleBody: pageToDisplay.hubInnerParent.hubInnerContent.reduce((acc, curr) => acc + curr.h2 + curr.h3 + curr.p, '').replace(/(<([^>]+)>)|/ig,"")

            }}
        />
        : <StructuredData
            type="Article"
            data={{
                headline: seoSource.title,
                publisher: {
                    '@type': 'Organization',
                    name: 'Cato Networks',
                    logo: {
                        '@type': 'ImageObject',
                        url: data.logoPage.structuredOrganizationData.organizationData.structuredLogo.sourceUrl,
                        width: 155,
                        height: 60
                    },
                },
                image: {
                    '@type': 'ImageObject',
                    url: data.logoPage.structuredOrganizationData.organizationData.structuredLogo.sourceUrl
                },
                author: {
                    '@type': 'Organization',
                    name: 'Cato Networks'
                },
                mainEntityOfPage: {
                    '@type': "WebPage",
                    '@id': `https://www.catonetworks.com${props.location.pathname}`
                },
                datePublished: data.hubParent.dataDate,
                dateModified: data.hubParent.dataDate,
                dateCreated: data.hubParent.dataDate
            }}
        />;



    return (
        <Layout
            className='hub-page'
            lang={props.pageContext.lang}
            breadcrumbs={breadcrumbsLinks}
            whiteMenu={false}
            transparentMenu={true}
            darkText={true}
            yoastData={seoSource.seo}
            seoTitle={seoSource.pageSeoTitle.pageSeoTitle}
            title={seoSource.title}
            seoPath={props.location.pathname}
            wpid={props.databaseId}

        >
            {structuredData}
            <section className="top">
                <h1>{isHubLobby ? seoSource.title : (pageToDisplay.hubInnerParent.hubMainTitleAndGreenDescription && pageToDisplay.hubInnerParent.hubMainTitleAndGreenDescription.mainTitle) || pageToDisplay.title }</h1>
            </section>

            {content}

            {FAQcomp && FAQcomp.faq && FAQcomp.faq.length &&
            <FAQ
                headline={FAQcomp.faqHeadline}
                questions={ FAQcomp.faq.map(q => ({
                    question: q.faqQuestion,
                    answer: q.faqAnswer
                }))
                }
                button={ FAQcomp.faqButton ? {
                    label: FAQcomp.faqButton.buttonLabel && FAQcomp.faqButton.buttonLabel.length > 0 ? FAQcomp.faqButton.buttonLabel : null,
                    path: FAQcomp.faqButton.buttonLink && FAQcomp.faqButton.buttonLink.length > 0 ? FAQcomp.faqButton.buttonLink : null
                } : null}
            />
            }
        </Layout>
    )
}

export default HubPage;





export const query = graphql`
    fragment HubInner on WpPage {
        ...YoastData
        title
        path: uri
        id
        slug
        databaseId
        author {
            node {
                name
            }
        }
        dataDate: date(formatString: "DD-MM-YYYY")
        wpParent {
            node {
                id
                ... on WpPage {
                    title
                    templateFileName
                    pageSeoTitle {
                        pageSeoTitle
                    }
                    wpParent {
                        node {
                            ... on WpPage {
                                title
                                pageSeoTitle {
                                    pageSeoTitle
                                }
                            }
                        }
                    }
                }
            }
        }
        pageSeoTitle {
            pageSeoTitle
        }
        hubInnerParent {
            hubParentSummary
            buttonText
            hubMainTitleAndGreenDescription {
                mainTitle
                mainDescription
            }
            hubInnerContent {
                h2: hubH2Heading
                h3: hubH3Heading
                p: hubParagraph
                images: hubImages {
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    text
                }
                showLightbox
                resource {
                    ... on WpResource {
                        title
                        resourceFields {
                            resourceLink
                            resourceShortDescription
                            resourceSmallImage {
                                altText
                                localFile {
                                    publicURL
                                }
                            }
                            resourceButtonText
                        }
                    }
                }
            }
        }
    }

    query HubQuery($id: String!, $isInner: Boolean!, $parentId: Int!) {
        hubParent: wpPage (databaseId: {eq: $parentId}){
            ...YoastData
            title
            path: uri
            slug
            id
            content
            templateFileName 
            dataDate: date(formatString: "DD-MM-YYYY")
            pageSeoTitle {
                pageSeoTitle
            }
            hubTopParent{
              hubTopContent {
                h2: hubTopH2Heading
                h3: hubTopH3Heading
                p: hubTopParagraph
                images: hubTopImages {
                  image {
                    altText
                    localFile {
                      publicURL
                    }
                  }
                  text
                }
              }
            }
            ...FAQSection
            hubLobbyParent {
                hubInnerPages {
                    ... on WpPage {
                        id
                        title
                        hubInnerParent {
                            hubParentSummary
                            buttonText
                        }
                    }
                }
            }
        }
        currentInnerPage: wpPage (id: {eq: $id}) @include (if: $isInner) {
            ...HubInner
            ...FAQSection
        }
        hubInnerPages: allWpPage(
        filter: {
            status: {eq: "publish"},
            parentDatabaseId: { eq: $parentId }
        }){
            nodes {
                ...HubInner
            }
        }
        hubInnerInnerPages: allWpPage(
        filter: {
            status: {eq: "publish"},
            ancestors: { nodes: { elemMatch: {databaseId: { eq: $parentId}}} },
            parentDatabaseId: {ne: $parentId }
        }){
            nodes {
                ...HubInner
            }
        }
        logoPage: wpPage(databaseId: { eq: 12 }) {
            id
            structuredOrganizationData {
                organizationData {
                    structuredLogo {
                        sourceUrl
                    }
                }
            }
        }
    }
`
